import React from 'react'
import Devops from '../../images/assets/devop.svg'
import Private from '../../images/assets/private.svg'
import Certifide from '../../images/assets/certifide.svg'
import Project from '../../images/assets/project-ma.svg'


function Services({ whatwedo }) {
    return (
        <div className="why_service">
            <div className="containerwrap">
                <div className="services">
                    <div className="servicesTitle">
                        {whatwedo.title}
                    </div>
                    <div className="servicesHeading">
                        {whatwedo.heading}
                    </div>
                    <div className="servicesHeading">
                        {whatwedo.heading1}
                    </div>
                    <div className="ServicesFilterOptions">
                        <div className="ServicesFilterOption">
                            {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
                            <Devops />
                            <div className="ServicesFilterOptionName">{whatwedo.bulletpoints[0].title}</div>
                            <div className="ServicesFilterOptionDescription">{whatwedo.bulletpoints[0].des}</div>
                        </div>
                        <div className="ServicesFilterOption">
                            {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
                            <Private />

                            <div className="ServicesFilterOptionName">{whatwedo.bulletpoints[1].title}</div>
                            <div className="ServicesFilterOptionDescription">{whatwedo.bulletpoints[1].des}</div>
                        </div>
                        <div className="ServicesFilterOption">
                            {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
                            <Certifide />

                            <div className="ServicesFilterOptionName">{whatwedo.bulletpoints[2].title}</div>
                            <div className="ServicesFilterOptionDescription">{whatwedo.bulletpoints[2].des}</div>
                        </div>
                        <div className="ServicesFilterOption">
                            {/* <img src={search} alt="service" className="ServicesFilterOptionImg" /> */}
                            <Project />

                            <div className="ServicesFilterOptionName">{whatwedo.bulletpoints[3].title}</div>
                            <div className="ServicesFilterOptionDescription">{whatwedo.bulletpoints[3].des}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
