import React from 'react';
import { useState } from 'react';
import { navigate } from "gatsby";
function Navigation({ data, locale }) {
    const [slug, setSlug] = useState("");
    
    const handleClick = (val) => {
        setSlug(val);
        navigate(`/industries/#${val}`, { replace: true });
    }

    React.useEffect(() => {

        let selectedSlug = window?.location.href.split('#')[1]
        if(selectedSlug) {
            setSlug(selectedSlug);
        } else {
            navigate(`/industries/#ecommerce`, { replace: true });
        }
    });
    
    return (
            <div className="pageNav industryPage ">
                <ul className="pageNav-ul">
                <li key={1} onClick={(e) => handleClick("ecommerce")} className={slug === "ecommerce" ? 'active':''}>{data.l1}</li>
                    <li key={2} onClick={(e) => handleClick("fintech")} className={slug === "fintech" ? 'active':''}>{data.l2}</li>
                    <li key={3} onClick={(e) => handleClick("travel")} className={slug === "travel" ? 'active':''}>{data.l3}</li>
                    <li key={4} onClick={(e) => handleClick("wholesale-retail")} className={slug === "wholesale-retail" ? 'active':''}>{data.l4}</li>
                    <li key={5} onClick={(e) => handleClick("media-entertainment")} className={slug === "media-entertainment" ? 'active':''}>{data.l5}</li>
                    {/* <li><a href="#ecommerce">{data.l1}</a></li>
                    <li><a href="#fintech">{data.l2}</a></li>
                    <li><a href="#travel">{data.l3}</a></li>
                    <li><a href="#wholesale-retail">{data.l4}</a></li>
                    <li><a href="#media-entertainment">{data.l5}</a></li> */}
                    {/* <li><a href="#benifits">{data.l6}</a></li> */}
                    {/* <li><a href="#successstories">{data.l7}</a></li>
                    <li><a href="#supportedplatforms">{data.l8}</a></li> */}
                </ul>
            </div>
    )
}

export default Navigation
