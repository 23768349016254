import React from "react";
import { navigate, Link } from "gatsby";
import Navigation from "./Navigation";

const Heading = ({ herosection, locale, navbar }) => {
  return (
    <div className="industry_upper_class">
      <div className="serviceHeroSection" id="heading">
        <div className="containerwrap">
          <h4 className="serviceHeroSectionTitle ">{herosection.title}</h4>
          <h1 className="serviceHeroSectionHeading roboto">
            {herosection.heading}
          </h1>
          <div className="opsBtnwrap middelBtn">
            <Link className="opsBtnlink" to={`/contact-us/`}>
              <p>{herosection.btn}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="industryNavSection">
        <Navigation data={navbar} locale={locale} />
      </div>
    </div>
  );
};

export default Heading;
