import React from "react";
import Layout from "../components/layoutnew";
import Heading from "../components/industry/Heading";
import Overview from "../components/industry/overview";
import OurApproach from "../components/OurApproach";
import EnquireNow from "../components/EnquireNow";
import SuccessStories from "../components/SuccessStories";
import Platforms from "../components/industry/Platforms";
import Process from "../components/industry/Process";
import WhatWeDo from "../components/industry/WhatWeDo";
import Partner from "../components/homepage/partner";

import "../styles/service.scss";
import SEO from "../components/seo";
import { graphql } from "gatsby";
// import Benefits from "../components/solutions/service/Benefits";
const industryPage = ({ data }) => {
  const getIndustryData = (data) => {
    const values = data.allStrapiIndustryPage.edges[0].node;
    return values;
  };
  const getData = getIndustryData(data);
  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
      />
      <Heading
        navbar={getData.menuBar}
        herosection={getData.heroSection}
        locale={getData.locale}
      />
      <Overview industries={getData.industries} />
      <EnquireNow data={getData.cloudjourney} locale={getData.locale} />
      {/* <Benefits benifits={getData.benefits} /> */}
      <SuccessStories locale={getData.locale} />
      {/* <Platforms platform={getData.platform} locale={getData.locale} /> */}
      <Partner title={getData.platform.title} locale={getData.locale} />
    </Layout>
  );
};

export default industryPage;

export const query = graphql`
  query industryPage($locale: String!) {
    allStrapiIndustryPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          slug
          metaTitle
          metaDes

          cloudjourney {
            btn
            email
            title
          }
          heroSection {
            btn
            heading
            title
          }
          menuBar {
            l1
            l2
            l3
            l4
            l5
            l6
            l7
            l8
          }
          platform {
            des1
            des2
            heading
            title
          }
          industries {
            strapi_json_value {
              id
              des
              title
            }
          }
        }
      }
    }
  }
`;
